import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/tailwind.css";
import "./assets/css/global.scss";
import "./assets/css/style.css";
import VueFeather from "vue-feather";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  configure,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
// import * as rules from 'vee-validate/dist/rules';
import { required } from "vee-validate/dist/rules";
import displaySnackbar from "../src/services/utils/snackbar";
import VueSplide from "@splidejs/vue-splide";
import CLoader from "@/components/reuseables/CLoader";
import CLoaderBlue from "@/components/reuseables/CLoaderBlue";
import VueApexCharts from "vue-apexcharts";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";

import * as VueGoogleMaps from "vue2-google-maps";

import VueMeta from 'vue-meta'
import './registerServiceWorker'
import NetworkComponent from "@/components/reuseables/NetworkComponent.vue";

Vue.component("vue-feather", VueFeather);
Vue.component("NetworkComponent", NetworkComponent);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

dayjs.extend(LocalizedFormat);
dayjs().format("L LT");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueSplide);
Vue.prototype.$displaySnackbar = displaySnackbar;

Vue.use(VueApexCharts);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBc2jwskked57nbmXhFrngq4krQQeFXKxU",
    libraries: "places", // necessary for places input
  },
});
Vue.component("apexchart", VueApexCharts);


Vue.component("loader-icon", CLoader);
Vue.component("loader-blue", CLoaderBlue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });

extend("required", {
  ...required,
  message: "The {_field_} field is required",
});

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

localize("en", en);
