export const cryptoUtils = {
    async generateKey() {
        return crypto.subtle.generateKey(
            { name: 'AES-CBC', length: 256 },
            true,
            ['encrypt', 'decrypt']
        );
    },

    async encrypt(data, key) {
        const iv = crypto.getRandomValues(new Uint8Array(16)); // Random IV
        const encoder = new TextEncoder();
        const encodedData = encoder.encode(JSON.stringify(data));

        const encrypted = await crypto.subtle.encrypt(
            { name: 'AES-CBC', iv },
            key,
            encodedData
        );

        return {
            iv: Array.from(iv),
            encryptedData: Array.from(new Uint8Array(encrypted)),
        };
    },

    async decrypt(encryptedObj, key) {
        const iv = new Uint8Array(encryptedObj.iv);
        const encryptedData = new Uint8Array(encryptedObj.encryptedData);

        const decrypted = await crypto.subtle.decrypt(
            { name: 'AES-CBC', iv },
            key,
            encryptedData
        );

        const decoder = new TextDecoder();
        return JSON.parse(decoder.decode(decrypted));
    },
};
