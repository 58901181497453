import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import VueJwtDecode from "vue-jwt-decode";
import router from "../../router";
import axios from "axios";
import authHeader from "./auth-header";
import {cryptoUtils} from "@/services/utils/cryptoUtils";


const {
  // GET,
  POST,
  // PUT,
  // PATCH,
  // DELETE
} = METHODS;

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};


const authServiceCallEndpoint = ({ method, url, data}) => {
  return axios[method](process.env.VUE_APP_AUTH_BASE_URL+url,data,{headers:headers})
};

export const login = async  (jsonData) => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  jsonData.client_id = process.env.VUE_APP_AUTH_BASE_LOGIN_CLIENT_ID;
  jsonData.grant_type = "password"

  const formData = encodeMapToFormUrlEncoded(jsonData)
  const url = "/realms/nomadicpod/protocol/openid-connect/token";
  const { data } = await authServiceCallEndpoint({
    method: POST,
    data: formData,
    url,
  });
  getUserData(data.access_token)
};

export const getUserData =(data) =>{
  let decodedToken = VueJwtDecode.decode(data);
  const base64Url = data.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
      atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
  );
  sessionStorage.setItem("userData", JSON.stringify(decodedToken));
  sessionStorage.setItem("userToken", JSON.stringify(data));

  return jsonPayload;
}

export const isTokenValid = (exp)=>{
  if (Math.floor(Date.now() / 1000) >= exp){
    sessionStorage.removeItem('userData')
    sessionStorage.removeItem('userToken')
    reAuthorize().then(()=>{
      window.location.reload();
    })

  }
}

export const isTokenExpired =  (token) => {
  if (!token) return true;
  try {
    const decodedToken = VueJwtDecode.decode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
}

export const  getClientAuthToken = async ()=>{
  let request = {}
  request.client_id = process.env.VUE_APP_AUTH_BASE_CLIENT_ID;
  request.client_secret = process.env.VUE_APP_AUTH_BASE_CLIENT_SECRET;
  request.grant_type = "client_credentials";
  const formData = encodeMapToFormUrlEncoded(request)
  const url = "/realms/nomadicpod/protocol/openid-connect/token";
  const { data } = await authServiceCallEndpoint({
    method: POST,
    data: formData,
    url,
  });
  sessionStorage.setItem("userToken", JSON.stringify(data));
}

export const validUsername = async (params) =>{
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken){
    await getClientAuthToken()
  }
  const url = "/admin/realms/nomadicpod/users"
  return await axios.get(process.env.VUE_APP_AUTH_BASE_URL+url,{params:params,headers:authHeader()})
}

function encodeMapToFormUrlEncoded(jsonObject) {
  const encodedPairs = [];
  for (const [key, value] of Object.entries(jsonObject)) {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value.toString());
    encodedPairs.push(`${encodedKey}=${encodedValue}`);
  }
  return encodedPairs.join('&');
}

export const stillOnBoarding = () =>{
  let companyData = JSON.parse(sessionStorage.getItem("companyData"))
  if (companyData && !companyData.companyLogo){
    router.push({name: 'OnboardingIdentity'}).then()
  }
  else if (companyData && !companyData.address.houseNumber){
    router.push({name: 'OnboardingAddress'}).then()
  }
  else if (companyData && !companyData.secondaryEmail){
    router.push({name: 'OnboardingContact'})
  }
  else if (companyData && !companyData.cacRegistrationNumber){
    router.push({name: 'OnboardingVerification'}).then()
  }
  else if (companyData && !companyData.accountDetail){
    router.push({name: 'OnboardingAccountDetails'})
  }
}
export const logout = () => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  sessionStorage.removeItem("companyData");
  sessionStorage.removeItem("staffData")
  sessionStorage.clear()
  router.push({name:"TransportCompanyLogin"})
};



export const transportCompanySignUp = async (data) => {
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken){
    await getClientAuthToken()
  }
  const url = "/api/transport-company/signup";
  return callEndpoint({ method: POST, url, data });
};
export const verifyEmail = async (data) => {
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken){
    await getClientAuthToken()
  }
  const url = "/auth/accounts/verify-registration/";
  return callEndpoint({ method: POST, url, data });
};
export const resetPassword = async (data) => {
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken){
    await getClientAuthToken()
  }
  const url = "/api/user/password-recovery";
  return callEndpoint({ method: POST, url, data });
};
export const resetNewPassword = async(data, query) => {
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken){
    await getClientAuthToken()
  }
  const url = `/api/user/reset-new-password/?code=${query}`;
  return callEndpoint({ method: POST, url, data });
};

export const resetManagerNewPassword = async (data, query) => {
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken){
    await getClientAuthToken()
  }
  const url = `/api/user/verify/?code=${query}`;
  return callEndpoint({ method: POST, url, data });
};

export const reAuthorize = async()=>{
  let loginData = sessionStorage.getItem("loginData");
  if(loginData) {
    let key = await cryptoUtils.generateKey()
    let decryptedData = await cryptoUtils.decrypt(JSON.parse(loginData), key)
    await login(decryptedData)
  }
}
