var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",style:({ color: _vm.companyColor }),on:{"click":_vm.backToAllTrip}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("All Trips")])],1),_c('v-col',{staticClass:"\n        tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-32 tw-w-full\n      ",attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between lg:tw-mb-10 tw-mb-5"},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v("New Trip")])]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addATrip)}}},[_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-mb-8"},[_c('v-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Trip Type")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose a trip with a single or multiple drop-offs.")])])]),_c('v-col',{staticClass:"tw-flex tw-flex-row  tw-justify-between",attrs:{"lg":"8"}},[_c('v-radio-group',{staticClass:"tw-w-full tw-justify-between tw-flex tw-items-center ",attrs:{"row":_vm.$vuetify.breakpoint.mdAndUp,"hide-details":""},model:{value:(_vm.tripType),callback:function ($$v) {_vm.tripType=$$v},expression:"tripType"}},[_c('div',{style:({width : _vm.$vuetify.breakpoint.mdAndUp ? '52%': '100%'})},[_c('v-radio',{attrs:{"value":"single drop-off","label":"Single Drop-Off","color":_vm.companyColor}})],1),_c('div',{staticClass:"tw-my-5 lg:tw-my-0",style:({width : _vm.$vuetify.breakpoint.mdAndUp ? '48%': '100%'})},[_c('v-radio',{attrs:{"value":"multiple drop-offs","label":"Multiple Drop-Offs","color":_vm.companyColor}})],1)])],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-16 lg:tw-mb-10 lg:tw-mt-0"},[_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row "},[_c('v-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Date (s)")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the date (s) and time for the trip")])])]),_c('v-col',{staticClass:"tw-flex tw-flex-row tw-justify-between",attrs:{"lg":"8"}},[_c('div',{staticStyle:{"width":"48%"}},[_c('ValidationProvider',{attrs:{"name":"Trip Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"placeholder":"2022-09-9","solo":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-calendar-month-outline ")])]},proxy:true}],null,true),model:{value:(_vm.tripDates),callback:function ($$v) {_vm.tripDates=$$v},expression:"tripDates"}},on))]}}],null,true),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","min":_vm.minDate,"color":_vm.companyColor,"multiple":"","scrollable":""},model:{value:(_vm.tripDates),callback:function ($$v) {_vm.tripDates=$$v},expression:"tripDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":_vm.companyData.companyColor},on:{"click":function($event){_vm.fromDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":_vm.companyData.companyColor},on:{"click":function($event){return _vm.$refs.fromDateMenu.save(_vm.tripDates)}}},[_vm._v("OK ")])],1)],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticStyle:{"width":"48%"}},[_c('ValidationProvider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"items":_vm.timeOfTheDay,"placeholder":"6:00AM","append-icon":"mdi-chevron-down","solo":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-clock-outline ")])]},proxy:true}],null,true),model:{value:(_vm.tripTime),callback:function ($$v) {_vm.tripTime=$$v},expression:"tripTime"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-16 lg:tw-mb-10 lg:tw-mt-0"},[_c('v-row',{staticClass:"tw-flex"},_vm._l((_vm.mergeRoutes),function(route,index){return _c('div',{key:index,staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center"},[_c('v-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Route")]),_c('p',{staticClass:"label-info"},[_vm._v("Select the route for the trip.")])])]),_c('v-col',{staticClass:"tw-flex tw-flex-row tw-w-full tw-justify-between",style:({flexDirection : !route.tripRegion || route.tripRegion === 'local' ? 'row': 'column'}),attrs:{"lg":"8"}},[_c('ValidationProvider',{style:({width :!route.tripRegion || route.tripRegion === 'local' ? '48%' : '100%' }),attrs:{"name":"Route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{staticClass:"text-capitalize",attrs:{"items":_vm.routes,"placeholder":"Choose Route","append-icon":"mdi-chevron-down","item-text":_vm.getRouteText,"item-value":"id","solo":"","hide-details":"","color":_vm.companyColor},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('icons',{attrs:{"name":"routes","color":_vm.companyColor}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.toSentenceCase(item.departureTerminalName))+" "+_vm._s(_vm.toSentenceCase(item.departureTerminalCity))+" "+_vm._s(_vm.toSentenceCase(item.departureTerminalState))+" - "+_vm._s(_vm.toSentenceCase(item.destinationTerminalName))+" "+_vm._s(_vm.toSentenceCase(item.destinationTerminalCity))+" "+_vm._s(_vm.toSentenceCase(item.destinationTerminalState)))])]}}],null,true),model:{value:(route.route),callback:function ($$v) {_vm.$set(route, "route", $$v)},expression:"route.route"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"tw-flex tw-flex-row",style:({width : !route.tripRegion || route.tripRegion === 'local' ? '48%' : '100%' })},[(!route.tripRegion || route.tripRegion === 'local')?_c('div',{staticClass:"tw-flex tw-w-11/12"},[_c('ValidationProvider',{staticClass:"tw-w-full",attrs:{"name":"Route Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"color":_vm.companyColor,"placeholder":"8000","solo":"","hide-details":"","type":"Number","prefix":"₦"},model:{value:(route.routeAmount),callback:function ($$v) {_vm.$set(route, "routeAmount", $$v)},expression:"route.routeAmount"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_c('div',{staticClass:"tw-flex tw-w-11/12 tw-flex-col"},_vm._l((route.tripCategories),function(tripCategory,index){return _c('div',{key:index,staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center\n                    tw-justify-between tw-py-3"},[_c('v-checkbox',{attrs:{"color":_vm.companyColor},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"text-capitalize"},[_vm._v(_vm._s(tripCategory.type))])]},proxy:true}],null,true),model:{value:(tripCategory.selected),callback:function ($$v) {_vm.$set(tripCategory, "selected", $$v)},expression:"tripCategory.selected"}}),_c('ValidationProvider',{attrs:{"name":"Route Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"color":_vm.companyColor,"placeholder":"8000","solo":"","hide-details":"","type":"Number","prefix":"₦"},model:{value:(tripCategory.tripAmount),callback:function ($$v) {_vm.$set(tripCategory, "tripAmount", $$v)},expression:"tripCategory.tripAmount"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)}),0),_c('div',{staticClass:"tw-w-auto"},[_c('v-tooltip',{attrs:{"top":"","color":_vm.companyColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"tw-cursor-pointer",on:{"click":_vm.addPickupRoute}},'v-col',attrs,false),on),[_c('icons',{attrs:{"name":"circular-add","color":_vm.companyColor}})],1)]}}],null,true)},[_c('span',[_vm._v("Add route")])])],1),(_vm.mergeRoutes.length > 1)?_c('div',{staticClass:"tw-w-auto"},[_c('v-col',{staticClass:"tw-cursor-pointer",on:{"click":function($event){return _vm.removePickupRoute(index)}}},[_c('icons',{attrs:{"name":"confirm-delete","height":"20","width":"20","color":_vm.companyColor}})],1)],1):_vm._e()])],1)],1)}),0)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-16 lg:tw-mb-10 lg:tw-mt-0"},[_c('v-row',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle")]),_c('p',{staticClass:"label-info"},[_vm._v("Select vehicle type & seat capacity.")])])]),_c('v-col',{staticClass:"tw-flex tw-flex-row tw-w-full lg:tw-w-8/12 tw-justify-between"},[_c('div',{staticStyle:{"width":"48%"}},[_c('ValidationProvider',{attrs:{"name":"Vehicle Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"items":_vm.vehicleTypes,"placeholder":"Hummer Bus","solo":"","append-icon":"mdi-chevron-down","hide-details":""},model:{value:(_vm.selectedVehicleType),callback:function ($$v) {_vm.selectedVehicleType=$$v},expression:"selectedVehicleType"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticStyle:{"width":"48%"}},[_c('ValidationProvider',{attrs:{"name":"Vehicle Capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"items":_vm.vehicleCapacities,"placeholder":"15 Seater","solo":"","append-icon":"mdi-chevron-down","hide-details":""},model:{value:(_vm.selectedVehicleCapacity),callback:function ($$v) {_vm.selectedVehicleCapacity=$$v},expression:"selectedVehicleCapacity"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-14 lg:tw-mb-10 lg:tw-mt-0"},[_c('div',{staticClass:"\n              tw-flex tw-w-full tw-justify-end tw-items-center\n              action-btn-div\n              tw-my-10\n            "},[_c('v-btn',{staticClass:"first-btn",style:({
                color: _vm.companyColor,
                border: '1px solid' + _vm.companyColor,
              }),attrs:{"text":""},on:{"click":_vm.backToAllTrip}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Done ")])],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }