
export default {
    SET_COMPANY_DATA: (state, payload) => {
        state.companyData = payload;

    },

    SET_LOADING: (state,payload) =>{
        state.loading = payload
    },

    SET_IS_ONLINE: (state, payload) =>{
        state.isOnline = payload
    },
    SET_IS_SLOW_CONNECTION: (state, payload) =>{
        state.isSlowConnection = payload
    }
};
