/* eslint-disable no-unused-vars */
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import axios from "axios";

import authHeader from "@/services/api/auth-header";
import {
  data
} from "autoprefixer";

let RESOURCE_URL = "";

if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";

const CITY_BASE_URL =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

const header = {
  'Content-Type': 'application/json',
  'Authorization': 'dskjdks',
  'sandbox-key': 'NrfBD4sIVcg7eGkAhlvVy1BWu6oEArIU1650754889'
}

export const getAllBanks = async () => {
  const url = 'https://fsi.ng/api/v1/flutterwave/v3/banks/NG?country=NG'
  return axios.get(url, {
    headers: header
  })
}

export const getCountryCodes = async () => {
  const url = `https://restcountries.com/v3.1/all?fields=idd,flags,name`
  return axios.get(url, {
    headers: headers
  })
}

export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL, {
    headers
  });
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country, {
    headers
  });
};

export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data, {
    headers
  });
};

export const getLatLong = async (data) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data.houseNumber}+${data.streetName}+${data.city},+${data.state},+${data.country}
  &key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
  return axios.get(url);
};

export const getTransportByWebsite = async (website) => {
  const url = `/api/transport-company/by-website/${website}`;
  return callEndpoint({
    url
  })
};

export const onBoardTransportCompany = async (data) => {
  const url = `/api/transport-company/on-boarding`;
  return callEndpoint({
    method: PATCH,
    url,
    data,
    needsAuth: true
  });
};

export const getCompanyData = async (data) => {
  const url = `/api/transport-company/get/email`;
  return callEndpoint({
    method: POST,
    url,
    data,
    needsAuth: true
  });
};

export const createPaymentLog = async (data) => {
  const url = process.env.VUE_APP_NPAY_BASE_URL+'/api/payment-log/create'
  return axios.post(url, data )
}
export const verifyPaystackPayment = async  (data) =>{
  const  url = `/api/paystack/verify-payment`
  return callEndpoint({method:POST,url,data},process.env.VUE_APP_NPAY_BASE_URL)
}

export const getWallet = async (data) => {
  const url = `/api/wallet/get-pos-wallet`;
  return callEndpoint({method: POST, url, data},process.env.VUE_APP_NPAY_BASE_URL);
}

export const debitTspForPosUsage = async (data) => {
  const url = `/api/wallet/debit-tsp-for-pos-usage`;
  return callEndpoint({method: POST, url, data},process.env.VUE_APP_NPAY_BASE_URL);
}
export const reserveBooking = async (data)=>{
  const url = "/api/booking/reserve-booking"
  return callEndpoint({method:POST,url,data})
}
export const getBookingByPaymentReference = async (data) => {
  const url = `/api/booking/get-by-payment-reference`
  return callEndpoint({method:POST,url,data})
}

export const addTerminal = async (data) => {
  const url = `/api/transport-company/terminal/add`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};
export const getTransportCompanyTerminals = async (id) => {
  const url = `/api/transport-company/terminal/${id}/all`;
  return callEndpoint({
    url
  });
};

export const getTransportCompanyTerminalCount = async (transportCompanyId) => {
  const url = `/api/transport-company/terminal/count-by-transport-company/${transportCompanyId}`;
  return callEndpoint({
    url
  });
};

export const addFleet = async (data, companyId, terminalId) => {
  const url = `/api/fleet/new-fleet/${companyId}/${terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const deleteFleet = async (companyId, fleetId) => {
  const url = `/api/fleet/delete/${companyId}/${fleetId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const addTerminalManager = async (data, companyId, terminalId) => {
  const url = `/api/terminal-manager/new/${companyId}/${terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data,
    needsAuth: true
  });
};

export const getCompanyManagers = async (id) => {
  const url = `/api/terminal-manager/get-all/${id}`;
  return callEndpoint({
    url
  });
};
export const updateTerminal = async (data, companyId, terminalId) => {
  const url = `/api/transport-company/terminal/${companyId}/${terminalId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data,
    needsAuth: true
  });
};

export const deleteTerminal = async (companyId, terminalId) => {
  const url = `/api/transport-company/terminal/${companyId}/${terminalId}`;
  return callEndpoint({
    method: DELETE,
    url,
    needsAuth: true
  });
};

export const addRoute = async (data) => {
  const url = `/api/route/create/${data.transportCompanyId}/${data.departureTerminalId}/${data.destinationTerminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const getAllTransportCompanyRoute = async (id) => {
  const url = `/api/route/get-all/${id}`;
  return callEndpoint({
    url
  });
};

export const deleteRoute = async (data) => {
  const url = `/api/route/delete/${data.transportCompanyId}/${data.routeId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const updateRoute = async (data) => {
  const url = `/api/route/update/${data.transportCompanyId}/${data.routeId}/${data.departureTerminalId}/${data.destinationTerminalId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data
  });
};

export const getRouteById = async (data) => {
  const url = `/api/route/get/${data.transportCompanyId}/${data.routeId}`;
  return callEndpoint({
    url
  });
};

export const updateTerminalManager = async (
  data,
  companyId,
  terminalId,
  managerId
) => {
  const url = `/api/terminal-manager/update/${companyId}/${terminalId}/${managerId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data,
    needsAuth: true
  });
};

export const getManagerById = async (companyId, managerId) => {
  const url = `/api/terminal-manager/get/${companyId}/${managerId}`;
  return callEndpoint({
    url
  });
};

export const getManagerByEmailOrPhoneNumber = async (emailOrPhoneNumber) => {
  const url = `/api/terminal-manager/get-by-email-or-phone-number/${emailOrPhoneNumber}`
  return callEndpoint({
    url
  })
};

export const getBookingAdminByEmailOrPhoneNumber = async (emailOrPhoneNumber) => {
  const url = `/api/booking-admin/get-by-email-or-phone-number/${emailOrPhoneNumber}`
  return callEndpoint({
    url
  })
};

export const deleteManagerById = async (data) => {
  const url = `/api/terminal-manager/delete/${data.transportCompanyId}/${data.terminalManagerId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const getTerminalById = async (terminalId) => {
  const url = `/api/transport-company/terminal/${terminalId}`;
  return callEndpoint({
    url
  });
};

export const getAllCompanyFleets = async (id) => {
  const url = `/api/fleet/get-all/${id}`;
  return callEndpoint({
    url
  });
};

export const transferFleet = async (data) => {
  const url = `/api/fleet/transfer-fleet`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const getFleetById = async (data) => {
  const url = `/api/fleet/get-fleet-by-id/${data.transportCompanyId}/${data.fleetId}`
  return callEndpoint({
    url
  })
}
export const getCompanyTerminalStat = async (transportCompanyId) => {
  const url = `/api/trip/completed-trips-by-terminal/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getCompanyBookingsPerTerminalCities = async (transportCompanyId) => {
  const url = `/api/trip/completed-trips-by-terminal-city/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getTerminalBookingsByDate = async (data) => {
  const url = `/api/booking/transport-company-terminal-booking-by-date/${data.transportCompanyId}/${data.date}`
  return callEndpoint({
    url
  })
}

export const getCompanyBookingsPerDay = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-bookings-per-day/${transportCompanyId}`
  return callEndpoint({
    url
  })
}
export const getCompanyTripsPerDay = async (transportCompanyId) => {
  const url = `/api/trip/get-total-trips-per-day/${transportCompanyId}`
  return callEndpoint({
    url
  })
}
export const getCompanyRevenuePerDay = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-revenue-per-day/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const addBookingAdmin = async (data) => {
  const url = `/api/booking-admin/new/${data.transportCompanyId}/${data.terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const getCompanyBookingAdmins = async (id) => {
  const url = `/api/booking-admin/get-all/${id}`;
  return callEndpoint({
    url
  });
};

export const getBookingAdminById = async (data) => {
  const url = `/api/booking-admin/get/${data.transportCompanyId}/${data.bookingAdminId}`;
  return callEndpoint({
    url
  });
};

export const updateBookingAdminById = async (data) => {
  const url = `/api/booking-admin/update/${data.transportCompanyId}/${data.terminalId}/${data.bookingAdminId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data
  });
};

export const deleteBookingAdmin = async (data) => {
  const url = `/api/booking-admin/delete/${data.transportCompanyId}/${data.bookingAdminId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const addPilot = async (data) => {
  const url = `/api/pilot/new-pilot/${data.transportCompanyId}/${data.terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const getAllCompanyPilot = async (id) => {
  const url = `/api/pilot/get-all-of-transport-company/${id}`
  return callEndpoint({
    url
  })
}

export const assignFleetToPilot = async (data) => {
  const url = `/api/pilot/assign-to-fleet/${data.transportCompanyId}/${data.terminalId}/${data.pilotId}/${data.fleetId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const getPilotById = async (data) => {
  const url = `/api/pilot/get/${data.transportCompanyId}/${data.pilotId}`
  return callEndpoint({
    url
  })
}

export const updatePilotById = async (data) => {
  const url = `/api/pilot/update/${data.transportCompanyId}/${data.terminalId}/${data.pilotId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const deletePilotById = async (data) => {
  const url = `/api/pilot/delete/${data.transportCompanyId}/${data.pilotId}`
  return callEndpoint({
    method: DELETE,
    url
  })
}

export const addTrip = async (data) => {
  const url = `/api/trip/new-trip/${data.transportCompanyId}/${data.routeId}`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const addMultipleTrip = async (data) => {
  const url = `/api/trip/multiple-new-trip/${data.transportCompanyId}`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllTransportCompanyTrips = async (id) => {
  const url = `/api/trip/get-all/${id}`
  return callEndpoint({
    url
  })
}

export const getTotalTripCountByTransportCompany = async (transportCompanyId) => {
  const url = `/api/trip/count-by-transport-company/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const assignFleetToTrip = async (data) => {
  const url = `/api/trip/give-fleet-trip/${data.transportCompanyId}/${data.terminalId}/${data.fleetId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const getAllPendingTrips = async (transportCompanyId) => {
  const url = `/api/trip/pending-trip/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getAllPendingTripsByPage = async (data) => {
  const url = `/api/trip/get-all-transport-company-pending-trip-by-page`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getPendingTripsBySearchQuery = async (data) => {
  const url = `/api/trip/get-all-transport-company-pending-trip-by-search-query`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getPendingTripsByTakeOffDateAndTime = async (data) => {
  const url = `/api/trip/get-all-transport-company-pending-trips-by-take-off-date-and-time`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const  upgradeKudaAccount = async (data) =>{
  const url = `/api/wallet/upgrade-user-wallet`
  return callEndpoint({method:PATCH, url,data})
}

export const getAllTripsByPage = async (data) => {
  const url = `/api/trip/get-all-transport-company-trips-by-page`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const cancelTrip = async (data) => {
  const url = `/api/trip/cancel-trip/${data.transportCompanyId}/${data.terminalId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const tripCompleted = async (data) => {
  const url = `/api/trip/completed-trip/${data.transportCompanyId}/${data.newTerminalId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const deleteTrip = async (data) => {
  const url = `/api/trip/delete/${data.transportCompanyId}/${data.tripId}`
  return callEndpoint({
    method: DELETE,
    url
  })
}

export const getTripByTransportCompanyIdAndTripId = async (data) => {
  const url = `/api/trip/get/${data.transportCompanyId}/${data.tripId}`
  return callEndpoint({
    url
  })
}

export const addBookingCode = async (data) => {
  const url = `/api/booking/booking-code/add`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getPassengerDetail = async (bookingCode) => {
  const url = `/api/booking/booking-code/get-traveller-details-by-code/${bookingCode}`
  return callEndpoint({
    url
  })
}

export const verifyPassengerDetailExist = async (detail) => {
  const url = `/api/booking/booking-code/verify-traveller-details-exist/${detail}`
  return callEndpoint({
    url
  })
}
export const getBookingCodeByPhoneNumber = async (phoneNumber) => {
  const url = `/api/booking/booking-code/get-booking-code-by-phone-number/${phoneNumber}`
  return callEndpoint({
    url
  })
}

export const sendOtpToPassenger = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/send`
  return axios.post(url, data)
}

export const verifyPassengerOtp = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/verify`
  return axios.post(url, data)
}

export const addBooking = async (data) =>{
  const url = `/api/booking/add-booking`
  return callEndpoint({method:POST,url,data})
}
export const addLog = async (data)=>{
  const url = "/api/general-log/create"
  return callEndpoint({method:POST,url,data})
}

export const getAllTransportCompanyBookings = async (data) => {
  const url = `/api/booking/transport-company/get-all`
  let result = callEndpoint({method:POST,url,data})
  console.log(result)
  console.log("API res----------------------------")
  return result
}

export const getAllTransportCompanyBookingCount = async (transportCompanyId) => {
  const url = `/api/booking/count-by-transport-company/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getAllCompletedBookingsByTerminal = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-terminal-bookings/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getTransportCompanyRevenue = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-revenue/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const updatePassword = async (data) => {
  const url = `/api/user/change-password/${data.email}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getManifestByTransportCompanyId = async (transportCompanyId) => {
  const url = `/api/manifest/get-all-manifest-of-transport-company/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getManifestById = async (data) => {
  const url = `/api/manifest/get-manifest-by-transport-company-id-and-manifest-id/${data.transportCompanyId}/${data.manifestId}`
  return callEndpoint({
    url
  })
}

export const unReserveSeats = async (data) => {
  const url = `/api/customer-trip/un-reserve-seat/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const reserveSeats = async (data) => {
  const url = `/api/customer-trip/reserve-seat/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getWalletByUserEmail = async (email) => {
  const url = `/api/wallet/get-wallet-by-user-email/${email}`
  return callEndpoint({
    url
  })
}

export const getPaystackAccountDetails = async (email) => {
  const url = `/api/paystack/get-account-by-user-email/${email}`
  return callEndpoint({
    url
  })
}

// export const verifyPaystackPayment = async (userId, reference, data) => {
//   const url = `/api/paystack/verify-payment/${userId}/${reference}`
//   return callEndpoint({
//     method: PATCH,
//     url,
//     data
//   })
// }

export const withdrawFromWallet = async (data) => {
  const url = `/api/wallet/make-withdrawal-from-wallet`
  return callEndpoint({
    method: POST,
    url,
    data
  },process.env.VUE_APP_NPAY_BASE_URL)
}

export const addNewHiringCriteria = async (data) => {
  const url = `/api/hiring-criteria/add-new`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllHireRequest = async (data) => {
  const url = `/api/hiring/get-all`
  return callEndpoint({
    url
  })
}

export const getAllHireOfTsp = async (data) => {
  const url = `/api/hiring/get-all-hire-of-tsp`
  return callEndpoint({method:POST,url,data})
}

export const getAllHireRouteOfTSp = async  (data) => {
  const  url = `/api/hire-route/get-all-hire-routes-of-tsp`
  return callEndpoint({method:POST,url,data})
}

export const getAllHiringCriteria = async (data) => {
  const url = `/api/hiring-criteria/get-all-criteria-of-transport-company`
  return callEndpoint({
    method:POST,url,data
  })
}

export const getHiringCriteriaById = async (companyId, hiringCriteriaId) => {
  const url = `/api/hiring-criteria/get-by-id`;
  return callEndpoint({
    url
  });
};



export const getAllVehicleType = async (data) => {
  const url = `/api/hiring-criteria/get-all-vehicle-type-for-tsp`
  return callEndpoint({
    url,
    method: POST,
    data: data
  })
}

export const getWalletBalanceByUserEmail = async (email) => {
  const url = `/api/wallet/get-wallet-balance/${email}`
  return callEndpoint({
    url
  })
}

const paystackHeaders = {
  'Content-Type': 'application/json',
  "Authorization": 'Bearer ' + process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY

};

export const makePayment = async (data) => {
  const url = `https://api.paystack.co/transaction/initialize`
  return axios.post(url, data, {
    headers: paystackHeaders
  })
}

export const authorizePayment = async (data) => {
  return axios.post(data.url, data, {
    headers: paystackHeaders
  })
}

export const verifyPayment = async (ref) => {
  const url = `https://api.paystack.co/transaction/verify/${ref}`
  return axios.get(url, {
    headers: paystackHeaders
  })
}

export const updateCriteria = async (data) => {
  const url = `/api/hiring-criteria/update`
  return callEndpoint({method: PATCH, url, data})
}

export const deleteHiringCriteria = async (data) => {
  const url = `/api/hiring-criteria/delete-by-id`
  return callEndpoint({method: DELETE, url,data})
}

export const createNewHiringRoute = async (data) => {
  const url = `/api/hire-route/create-new`
  return callEndpoint({method:POST, url, data})
}


export const getAllTransportCompanyTripsByDate = async (data) => {
  const url = `/api/trip/get-all-transport-company-trips-by-date`
  return callEndpoint({method: POST, url, data})
}
export const updateTripAmount = async (data)=> {
  const url = `/api/aggregated-trips/update-trip-amount`
  return callEndpoint({method: PATCH, url, data})
}