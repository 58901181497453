import axios from "axios";
import authHeader from "@/services/api/auth-header";
import METHODS from "@/services/api/apiMethods";

let BASE_URL;

if (process.env.NODE_ENV === "development") {
    BASE_URL = process.env.VUE_APP_RESOURCE_SERVER_URL;
} else {
    BASE_URL = process.env.VUE_APP_RESOURCE_SERVER_URL;
}

const callEndpoint = async ({method, url, data, needsAuth = true}, SERVICE_BASE_URL = BASE_URL) => {
    const { GET, DELETE } = METHODS
    url = SERVICE_BASE_URL + url
    if (!method) method = GET
    if (method === GET || method === DELETE) {
        if (needsAuth) {
            return axios[method](url, { headers: await authHeader() })
        } else return axios[method](url)
    }  else {
        if (needsAuth) {
            return axios[method](url, data, { headers: await authHeader() })
        } else return axios[method](url, data)
    }
}

export default callEndpoint
