<template>
  <div>
    <v-alert
        v-if="!isOnline"
        type="error"
        outlined
        class="mb-0"
    >
      You are offline. Please check your connection.
    </v-alert>
    <v-alert
        v-else-if="isSlowConnection"
        type="warning"
        outlined
        class="mb-0"
    >
      Your connection is slow. Please be patient.
    </v-alert>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NetworkComponent",
  data() {
    return {
      isOnline: navigator.onLine,
      isSlowConnection: false,
    };
  },
  methods: {
    updateConnectionStatus() {
      this.isOnline = navigator.onLine;
      if (navigator.connection) {
        const { effectiveType } = navigator.connection;
        this.isSlowConnection = ["2g", "slow-2g"].includes(effectiveType);
      }
    },
  },
  created() {
    // Add event listeners
    window.addEventListener("online", this.updateConnectionStatus);
    window.addEventListener("offline", this.updateConnectionStatus);
    if (navigator.connection) {
      navigator.connection.addEventListener("change", this.updateConnectionStatus);
    }
    this.updateConnectionStatus(); // Initialize status
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener("online", this.updateConnectionStatus);
    window.removeEventListener("offline", this.updateConnectionStatus);
    if (navigator.connection) {
      navigator.connection.removeEventListener("change", this.updateConnectionStatus);
    }
  },
};
</script>
