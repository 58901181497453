<template>
  <div class="tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center"
       style="background: linear-gradient(135.05deg, rgba(240, 247, 254, 0.69) 41.6%, #FEF5F0 73.72%);">
    <v-col sm=12 lg="7" class="left-side lg:tw-flex">
      <v-col sm="12" class="left-side-body">
        <img src="https://res.cloudinary.com/myroadpadi/image/upload/v1703142128/production_asset/whitelogo_z2togt.png" alt="logo" style="height: 40px; width: 186px; cursor: pointer"
             >
        <label class="buddy">Hey Buddy!</label>
        <h6 class="welcome tw-mt-5">Welcome  Back.</h6>
        <p class="admin tw-mt-5">Transport Company Admin Board</p>
      </v-col>
    </v-col>
    <v-col sm="12" lg="5" class="right-side tw-flex tw-items-start lg:tw-items-center">
      <v-col sm="12" class="right-side-body">
        <img src="https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 tw-cursor-pointer"
            >

        
        <h6 class="welcome tw-mt-0">Welcome Back!</h6>
        <p class="admin tw-mt-1">Transport Company Admin Board</p>

        <h6 class="signup-header mb-1 mb-md-5">Sign In</h6>


        <validation-observer ref="observer" v-slot="{ handleSubmit }">

          <form @submit.prevent="handleSubmit(loginTransportCompany)">
        <ValidationProvider name="Email Or Phone Number" rules="required" v-slot="{ classes, errors }">
          <div class="tw-pt-4 mb-2" :class="classes">
            <v-text-field placeholder="Email Or PhoneNumber" data-testid="email-input" ref="emailInput" id="emailId"
                          solo hide-details type="text" autofocus v-model="loginData.username" required></v-text-field>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider name="Password" rules="required" v-slot="{ classes, errors }">
          <div class="tw-pt-4 mb-1" :class="classes">
            <v-text-field data-testid="password-input" ref="passwordInput"
              hide-details
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'text' : 'password'"
              solo
              placeholder="Password"
              name="password"
              v-model="loginData.password"
              id="password">
            </v-text-field>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

<!--        <div class="tw-pt-0">-->
<!--          <v-checkbox hide-details class="terms" v-model="keepMeIn">-->
<!--            <template #label>-->
<!--              <label>-->
<!--                Keep Me Logged in-->
<!--              </label>-->
<!--            </template>-->
<!--          </v-checkbox>-->
<!--        </div>-->
            <div class="tw-pt-4 tw-hidden md:tw-flex">
              <h6 class=" tw-flex already  tw-items-center tw-justify-start">
                Forget Password? <router-link :to="{name: 'TransportCompanyResetPassword'}" style="color: #004AAD; cursor: pointer">&nbsp;Click Here</router-link>
              </h6>

            </div>

        <base-button id class="mt-4" width="100%" button-text="Log In" data-testid="login-button" :loading="loading" type="submit"></base-button>
          </form>
        </validation-observer>
        <div data-testid="value-input" @click="logValues"></div>
      </v-col>
    </v-col>

  </div>
</template>

<script>

import BaseButton from "../components/reuseables/BaseButton";
import check from '../assets/check_one.svg'

import {getCompanyData} from "../services/api/APIService";
import {login} from "../services/api/AuthApiService";
import {cryptoUtils} from "@/services/utils/cryptoUtils";

export default {
  name: "TransportCompanyLoginView",
  components: { BaseButton },
  data(){
    return{
      title: "Welcome back.",
      description: "Transport Company Admin Board",
      value : false,
      confirmValue : false,
      dialog : false,
      keepMeIn : false,
      loading : false,
      check,
      loginData:{
        username: "",
        password : "",
      }
    }
  },
  watch:{

  },
  methods:{
    toggleModal(){
      this.dialog = !this.dialog
    },
    goHome(){
      window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,'_self')
    },
    logValues(){
      const emailInput = document.querySelector('[data-testid="email-input"]');
      const passwordInput = document.querySelector('[data-testid="password-input"]');

      if (emailInput && passwordInput) {
        this.loginData.username = emailInput.value
        this.loginData.password = passwordInput.value
        this.loginTransportCompany()
      } else {
        console.error('Element with data-testid "email-input" not found.');
      }
    },
   async loginTransportCompany(){
        this.loading = true
       if (this.loginData.username.startsWith("0")){
           this.loginData.username = this.loginData.username.replace("0","234")
       }
     let key   = await  cryptoUtils.generateKey()
     let encryptedData =  await cryptoUtils.encrypt(this.loginData,key)
     sessionStorage.setItem("loginData", JSON.stringify(encryptedData));
        login(this.loginData).then(async () => {
         let userData = JSON.parse(sessionStorage.getItem("userData"))
         if (userData.realm_access.roles.includes("TRANSPORT_COMPANY")){
           await this.handleTransportCompanyAccess(userData.email)
         }
         else if (userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER") || userData.realm_access.roles.includes("TRANSPORT_COMPANY_BOOKING_ADMIN")){
          await this.$router.push({name: "StaffDashboardContainer"})
         }
         else if (userData.realm_access.roles.includes("DEVELOPER")) {
           this.$displaySnackbar({
             message: "Access Denied",
             success: false
           })
           sessionStorage.removeItem("userToken");
           sessionStorage.removeItem("userData");
         }
         if (window.Android){
           // this.loginData.password = window.btoa(this.loginData.password)
           window.Android.getLoginDetail(JSON.stringify(this.loginData));
         }
          this.loading = false
        }).catch(err => {
            this.loading = false
              this.$displaySnackbar({
                message: err.response.data.error_description,
                success: false
              })

            }).finally(() => {
              this.loading = false
        })
      },
    async handleTransportCompanyAccess(email){
      let data = {}
      data.companyEmail = email
      await getCompanyData(data).then(res =>{
        if (Object.keys(res.data).length && res.data.transportCompanyVerified){
          this.$router.push({name :"TransportCompanyDashboard"})
        }
        else if(Object.keys(res.data).length && !res.data.transportCompanyVerified && !res.data.cacRegistrationNumber){
            this.$router.push({name: 'OnboardingIdentity'})
        }
        else {
          this.$displaySnackbar({
            message: "Company is not verified",
            success: false
          })
          this.loading = false
        }
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004AAD;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.forgot-password{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: rgba(117, 115, 115, 0.7);
}

.left-side{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url('../assets/sign-upbg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0!important;

  @media screen and (max-width: 1024px){
    display: none;
  }
}
.left-side-body{
  display: flex;
  width: 100%;
  background: rgba(0, 74, 173, 0.51);
  flex-direction: column;
  padding:5rem 7rem;
  justify-content: center;
}

.right-side{
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0!important;
  background: white;
}

.right-side-body{
  display: flex;
  // background: red;
  width: 100%;
  flex-direction: column;
  padding:7rem;
  justify-content: center;
  // align-items: center;
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}
.right-side-body >img{
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.right-side-body .welcome{
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0;
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.right-side-body .admin{
  font-family: 'Inter',serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4F4F4F;
  margin-left: 0;
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.title{
  font-family: "Inter",serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004AAD;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buddy{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-top: 4rem;
  margin-left: 0.8rem;
}
.welcome{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0.8rem;

  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-left: 0.8rem;
}


.signup-header{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #004AAD;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
}

.terms{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4F4F4F;
}

.already{
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #4F4F4F !important;
}


</style>